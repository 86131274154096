import Button from "@components/elements/button"
import Div from "@components/elements/div"
import Flex from "@components/elements/flex"
import Grid from "@components/elements/grid"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import Image from "@components/image"
import { processImages } from "@utils/process-images"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { useTheme } from "styled-components"
import PropTypes from "prop-types"
import { IconWrapper } from "./icon-wrapper"
import GradientText from "./gradient-text"

export const ServicesBlock = ({ style, text, klips }) => {
  const data = useStaticQuery(
    graphql`
      query ServicesBlock {
        images: allDirectusFiles(
          filter: {
            directus_id: {
              in: [
                "1c18c9fa-ecd1-4534-9396-b4b39b0ce816"
                "c68df9a6-e043-43cc-b8ef-5cd937a3cbd8"
              ]
            }
          }
        ) {
          edges {
            node {
              directus_id
              cdn
              id
              placeholder
              title
            }
          }
        }
      }
    `
  )

  const images = processImages(data.images.edges)
  const { color } = useTheme()

  return (
    <Flex textContainer gap="1rem" margin="0 auto 8rem" marginSm="0 auto 4rem" style={style}>
      <Heading color={text} as="h2" fontSize="3rem" margin="0 0 0.5rem">
        Save time, reduce risk, and get great results with&nbsp;
        <GradientText variant={klips ? "klips" : "powermetrics"}>
          Klipfolio Services
        </GradientText>
      </Heading>
      <Paragraph
        color={text}
        fontSize="1.1rem"
        margin="0 0 1rem"
        lineHeight={1.5}
      >
        Our data specialists and engineers will work with you to close gaps
        quickly and help deliver a solution that aligns with your business
        goals.
      </Paragraph>
      <Grid columns="40px 1fr" gap="2rem 1.5rem" margin="0 0 0.5rem">
        <IconWrapper>
          <Image
            file={images["1c18c9fa-ecd1-4534-9396-b4b39b0ce816"]}
            height={40}
          />
        </IconWrapper>
        <Div>
          <Heading color={text} as="h4" margin="0 0 1rem">
            Onboarding and Training
          </Heading>
          <Paragraph color={text} fontSize="1.1rem" lineHeight={1.5}>
            With customized training plans, our experts will guide your team on
            how to get the most out of Klipfolio products and deliver the right
            data to the right people.
          </Paragraph>
        </Div>
        <IconWrapper>
          <Image
            file={images["c68df9a6-e043-43cc-b8ef-5cd937a3cbd8"]}
            height={40}
          />
        </IconWrapper>
        <Div margin="0 0 1rem">
          <Heading color={text} as="h4" margin="0 0 1rem">
            Implementation Services
          </Heading>
          <Paragraph color={text} fontSize="1.1rem" lineHeight={1.5}>
            We&apos;ll get it done together. Think of our experts as your
            extended team. From data stack integration to metric and dashboard
            creation, rely on us to get your system up and running.
          </Paragraph>
        </Div>
      </Grid>
      <Button
        href="/services"
        notice={false}
        background={text ?? color.indigo600}
        arrow
      >
        Learn more
      </Button>
    </Flex>
  )
}

ServicesBlock.propTypes = {
  style: PropTypes.object,
  text: PropTypes.string,
  klips: PropTypes.bool,
}
